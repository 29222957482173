/**
 * @author Evgenij.Tyan
 * @version 1.0.0
 */
import theme from 'styled-theming';
import colors from '../colors';

/**
 * Styled props for multiselect
 */

export const fontColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.textColor},
});
export const borderColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.borderColorLight},
});
export const bgColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorWhite},
});
export const cancelButtonColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.textLinkColor},
});
