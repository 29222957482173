/**
* @author Evgenij.Tyan
* @version 1.0.0
*/
import theme from 'styled-theming';
import colors from '../colors';

export const bgColor = theme.variants('mode', 'kind', {
    error: {standard: colors.backgroundColorError},
    info: {standard: colors.backgroundColorNoticeInfo},
    success: {standard: colors.backgroundColorSuccess},
    warn: {standard: colors.backgroundColorWarning},
});

export const borderColor = theme.variants('mode', 'kind', {
    error: {standard: colors.borderColorError},
    info: {standard: colors.backgroundColorNoticeInfo},
    success: {standard: colors.borderColorSuccess},
    warn: {standard: colors.borderColorWarning},
});

export const color = theme.variants('mode', 'kind', {
    error: { standard: colors.textColorWhite },
    info: { standard: colors.textColor },
    success: { standard: colors.textColorWhite },
    warn: { standard: colors.textColorWhite },
});
