import {
    backgroundColorModal,
    backgroundColorWhite,
    borderColorDisabled,
    FreshAsphalt,
    FreshAsphalt50,
    Fury,
    textColorDanger,
} from './palette';

export const tableSettingBgColorModal = backgroundColorModal;
export const tableSettingDragAndDropBgColorModal = backgroundColorWhite;
export const tableSettingDragAndDropNoDropBorderColor = borderColorDisabled;
export const tableSettingDragAndDropRequiredColor = textColorDanger;
export const tableSettingTextColor = FreshAsphalt;
export const tableSettingTextColorDanger = Fury;
export const tableSettingTextColorSecondary = FreshAsphalt50;
