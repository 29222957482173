/**
 * @author Aleksandr Perkhunov
 * @version 1.0.0
 *
 * TABLE FOOTER
 */
import { backgroundColorHoverNormal, backgroundColorNormal, ColorGreenDark, textColorWhite } from './palette';

export const tableFooterButtonTextColor = textColorWhite;
export const tableFooterButtonBg = backgroundColorNormal;
export const tableFooterButtonBgHover = ColorGreenDark;
export const tableFooterMenuButtonBg = 'none';
export const tableFooterMenuButtonBgHover = backgroundColorHoverNormal;
