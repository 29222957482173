/**
 * @author Evgenij.Tyan
 * @author Aleksandr Perkhunov
 * @author Anton Panasenko
 * @version 8.3.0
 * @since 1.0.0
 *
 * Checkbox css style properties
 */
import theme from 'styled-theming';
import colors from '../colors';

export const bgColor = theme.variants('mode', 'kind', {
  normal: {standard: 'transparent'},
});

export const bgColorDisable = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorDisabled},
});

export const bgColorDown = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorDown},
});

export const bgColorHover = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorHover},
});

export const bgColorChecked = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorSuccess},
});

export const bgColorCheckedDisable = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorDisabledDark},
});

export const bgColorCheckedHover = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorHover},
});

export const bgColorCheckedDown = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorDown},
});

export const borderColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.borderColorLight},
});

export const borderColorHover = theme.variants('mode', 'kind', {
  normal: {standard: colors.borderColorHover},
});

export const borderColorChecked = theme.variants('mode', 'kind', {
  normal: {standard: colors.borderColorSuccess},
});

export const borderColorCheckedHover = theme.variants('mode', 'kind', {
  normal: {standard: colors.borderColorHover},
});

export const borderColorDown = theme.variants('mode', 'kind', {
  normal: {standard: colors.borderColorDown},
});
