/**
 * Loader CSS component
 *
 * Use any css style (css format)
 *
 * You can use three span as children. Set display: 'block' for each.
 *
 * Example:
 *      span:nth-child(1) {
 *          display: block;
 *      }
 *
 * @author Aleksandr Perkhunov
 * @version 1.4.0
 * @since 1.4.0
 */

export const customizeStyle = `
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;

    span {
        position:relative;
        display: none;
        width: 1em;
        height: 1em;

        &:before {
            display: block;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 4em;
            will-change: transform;
            animation: scaling 1s linear infinite;
        }

        &:nth-child(1) {
            display: block;
            margin-right: 0.5em;

            &:before {
                background-color: green;
            }
        }
        &:nth-child(2) {
            display: block;
            margin-right: 0.5em;

            &:before {
                animation-delay: 0.166s;
                background-color: green;
            }
        }
        &:nth-child(3) {
            display: block;

            &:before {
                animation-delay: 0.33s;
                background-color: purple;
            }
        }

        @keyframes scaling {
            0% {
                transform: scale(1);
            }
            33% {
                transform: scale(1.4);
            }
            66% {
                transform: scale(1);
            }
            100% {
                transform: scale(1);
            }
        }
    }
`;
