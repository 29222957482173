import {
    ButtonHoverGreen,
    ClearWhite,
    ColorGreyLight2,
    FreshAsphalt,
    FreshAsphalt50,
    Green,
    Link,
    SpbSky1,
} from './palette';

export const rangePickerItemColor = FreshAsphalt;
export const rangePickerHeaderBorderColor = SpbSky1;
export const rangePickerLinkColor = Link;

// Selected
export const rangePickerItemSelectedColor = ClearWhite;
export const rangePickerItemSelectedBgColor = Green;

// Hover
export const rangePickerItemHoverColor = ClearWhite;
export const rangePickerItemHoverBgColor = ButtonHoverGreen;
export const rangePickerBgHoverColor = ColorGreyLight2;

// In range
export const rangePickerItemInRangeColor = FreshAsphalt;
export const rangePickerItemInRangeBgColor = SpbSky1;

// Disabled
export const rangePickerItemDisabledColor = FreshAsphalt50;
export const rangePickerItemDisabledBgColor = ClearWhite;
