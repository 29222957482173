import {
    backgroundColorModal,
    backgroundColorNormal,
    borderColorDisabled,
    ColorGreenDark,
    ColorGrey6,
    ColorWhite,
} from './palette';

export const basketButtonBg = backgroundColorNormal;
export const basketButtonBgHover = ColorGreenDark;
export const basketButtonText = ColorWhite;

export const basketModuleBg = backgroundColorModal;

export const basketItemBg = ColorGrey6;
export const basketItemBorder = borderColorDisabled;
