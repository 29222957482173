/**
 * @author Yakov.Gushchin
 * @author Aleksandr Perkhunov
 * @author Evgeniy Naumov
 * @version 1.0.0
 *
 * Module containing theme components
 */
import * as accountSelect from './components/accountSelect';
import * as button from './components/button';
import * as checkbox from './components/checkbox';
import * as dashboard from './components/dashboad';
import colors from './colors';
import * as fonts from './fonts';
import * as icons from './icons';
import * as input from './components/input';
import * as loader from './loader';
import * as logoContainer from './components/logoContainer';
import * as multiSelect from './components/multiSelect';
import * as notice from './components/notice';
import * as popup from './components/popup';
import * as select from './components/select';
import * as selectItem from './components/selectItem';
import * as table from './components/table';
import * as tabMenu from './components/tabMenu';
import * as wizard from './components/wizard';
import * as palette from './colors/palette';

import './fonts.css';
import * as globalThemeConstants from './global';

module.exports = () => {
    return {
        accountSelect,
        button,
        checkbox,
        colors,
        dashboard,
        fonts,
        globalThemeConstants,
        icons,
        input,
        loader,
        logoContainer,
        multiSelect,
        notice,
        popup,
        select,
        selectItem,
        table,
        tabMenu,
        wizard,
        palette,
    };
};
