/**
 * @author Yakov.Gushchin
 * @author Andrey Dubov
 * @author Evgeniy Naumov
 * @author Ilya Baranchikov
 * @version 1.1.0
 * @version 1.0.0
 */
import errorDeliveryImage from "./assets/images/error/error-delivery.jpg";
import errorDeliveryImage2x from "./assets/images/error/error-delivery@2x.jpg";
import errorDeliveryImage3x from "./assets/images/error/error-delivery@3x.jpg";
import favicon from "./assets/images/brand-favicon.ico";
import logoImage from "./assets/images/brand-logo.svg";
import logoMini from "./assets/images/logo-mini.png";
import logoMiniLight from "./assets/images/logo-mini-light.png";
import logoImageLight from "./assets/images/brand-logo-light.svg";
import noDataImage from "./assets/images/nodata-image.png";
import onboardingWelcomeStartModal from "./assets/images/onboarding/onboarding-welcome-start-modal.jpg";
import statusError from "./assets/images/status/status-error.jpg";
import statusSuccess from "./assets/images/status/status-success.jpg";
import reportsBag from "./assets/images/reportsConstructor/reports-bag.png";
import reportsInf from "./assets/images/reportsConstructor/reports-inf.png";
import reportsLock from "./assets/images/reportsConstructor/reports-lock.png";
import reportsPhones from "./assets/images/reportsConstructor/reports-phones.png";
import reportsRub from "./assets/images/reportsConstructor/reports-rub.png";
import reportsSim from "./assets/images/reportsConstructor/reports-sim.png";
import reportsSubs from "./assets/images/reportsConstructor/reports-subs.png";

export {
    errorDeliveryImage,
    errorDeliveryImage2x,
    errorDeliveryImage3x,
    favicon,
    logoImage,
    logoMini,
    logoMiniLight,
    logoImageLight,
    noDataImage,
    onboardingWelcomeStartModal,
    statusError,
    statusSuccess,
    reportsBag,
    reportsInf,
    reportsLock,
    reportsPhones,
    reportsRub,
    reportsSim,
    reportsSubs,
};
