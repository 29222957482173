/**
 * @author Yakov.Gushchin
 * @author Aleksandr Perkhunov
 * @author Evgeniy Naumov
 * @version 8.10.0
 * @since 1.0.0
 *
 * @description Fonts css style properties
 */

import { linkDefaultColor, linkDefaultColorHover } from './colors/link';
import { textColor } from './colors/palette';
import { fontSizeBase, fontSizeSmall } from './global';

export const fontFamilies = {
	medium: 'MegaFonGraphikLCMedium',
	regular: 'MegaFonGraphikLCRegular',
}

export const textHeader2 = [
    { fontFamily: fontFamilies.medium },
    { fontSize: '32px' },
    { fontWeight: 600 },
    { lineHeight: '1.25' },
    { letterSpacing: '0.5px' },
    { color: textColor },
    { marginBottom: '24px' },
];

export const textHeader3 = [
    { fontFamily: fontFamilies.medium },
    { fontSize: '20px' },
    { fontWeight: 500 },
    { lineHeight: '1.6' },
    { color: textColor },
    { marginBottom: '20px' },
];

export const textHeader5 = [
    { fontFamily: fontFamilies.medium },
    { fontSize: '15px' },
    { fontWeight: 500 },
    { lineHeight: '1.6' },
    { color: textColor },
];

export const textMain = [
    { fontFamily: fontFamilies.regular },
    { fontSize: fontSizeBase },
    { fontWeight: 400 },
    { lineHeight: '1.6' },
    { letterSpacing: 'normal' },
    { color: textColor },
];

export const textMainBold = [
    { fontFamily: fontFamilies.medium },
    { fontSize: fontSizeBase },
    { fontWeight: 500 },
    { lineHeight: '1.6' },
    { letterSpacing: 'normal' },
    { color: textColor },
];

export const textSmall = [
    { fontFamily: fontFamilies.regular },
    { fontSize: fontSizeSmall },
    { fontWeight: 400 },
    { lineHeight: '1.33' },
    { letterSpacing: 'normal' },
    { color: textColor },
];

export const textSmallBold = [
    { fontFamily: fontFamilies.medium },
    { fontSize: fontSizeSmall },
    { fontWeight: 500 },
    { lineHeight: '1.33' },
    { letterSpacing: '0.3px' },
    { color: textColor },
];

export const textLink = [
    { fontFamily: fontFamilies.regular },
    { color: linkDefaultColor },
    { '&:hover': {
        color: linkDefaultColorHover,
    } },
];
