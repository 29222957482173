import { ClearWhite, FreshAsphalt, FreshAsphalt50, Green, SpbSky0, SpbSky1, SpbSky2 } from './palette';

// Title panel

export const tariffsAndServicesTitlePanelBgColor = SpbSky1;

// Cards

export const tariffsAndServicesCardBgColor = ClearWhite;
export const tariffsAndServicesCardBorderColorNormal = 'transparent';
export const tariffsAndServicesCardBorderColorHover = SpbSky2;
export const tariffsAndServicesCardBorderColorSelected = Green;
export const tariffsAndServicesCardColor = FreshAsphalt;
export const tariffsAndServicesCardCostColor = FreshAsphalt50;

// Accordion

export const tariffsAndServicesSideBordersColor = SpbSky1;
export const tariffsAndServicesSideBgColorHover = SpbSky0;
export const tariffsAndServicesSideColorDisabled = FreshAsphalt50;
export const tariffsAndServicesSideLeftBorderColorHover = Green;
export const tariffsAndServicesSideLevel1BgColorActive = Green;
export const tariffsAndServicesSideLevel1ColorActive = ClearWhite;

// Filter

export const tariffsAndServicesFilterButtonBgColor = ClearWhite;
export const tariffsAndServicesFilterButtonBorderColor = ClearWhite;
export const tariffsAndServicesFilterItemBgColorHover = Green;
export const tariffsAndServicesFilterItemColorHover = ClearWhite;
