import {
    _137C,
    backgroundColorSuccess,
    borderColorHover,
    borderColorLight,
    ClearWhite,
    ColorGreenLight,
    ColorGrey1,
    ColorGrey5,
    ColorGreyLight1,
    ColorGreyLight2,
    ColorWhite,
    FreshAsphalt50,
    Fury,
    Green,
    SpbSky0,
    SpbSky1,
    SpbSky050,
    textColor,
    textColorOpacity,
} from './palette';

export const tableGridBorderColor = ColorGreyLight2;
export const tableFixedBorderColor = ColorGreyLight2;

export const tableBorderTopColor = ColorGreyLight2;
export const tableHeadGhostBorderColorHover = ColorGreyLight1;
export const tableFooterBgColor = ColorWhite;

// resizer
export const tableResizerColorHover = ColorGreyLight2;
export const tableResizerColorActive = ColorGrey1;

// cells
export const tableCellBgColorChecked = SpbSky0;
export const tableCellBgColorHover = SpbSky050;
export const tableCellTextColorDanger = Fury;
export const tableCellTextColorSecondary = FreshAsphalt50;
export const tableCellTextColorSuccess = Green;
export const tableCellTextColorWarning = _137C;

// Header
export const tableHeaderBgColor = ClearWhite;

// Header Title
export const tableTitleButtonBgColor = ClearWhite;
export const tableTitleButtonBgColorHover = SpbSky1;

// Footer
export const tableFooterTotalBgColor = SpbSky0;

// Labels (markers)
export const tableLabelCounterBgColor = ColorGreyLight2;
export const tableLabelCounterBgColorHover = ColorGreyLight1;

// Filters
export const tableFilterTitleColor = textColorOpacity;
export const tableFilterTitleColorActive = textColor;
export const tableFilterDropdownBg = ColorWhite;
export const tableFilterItemColor = ColorGrey5;

// Loader
export const tableLoaderBackgroundGradient = `
    linear-gradient(to right,
        ${backgroundColorSuccess} 8%,
        ${ColorGreenLight} 1%
    );
`;

// Tooltip
export const tableTooltipShadow = ColorGrey1;

// Expanded column
export const tableExpandButtonBorderColor = borderColorLight;
export const tableExpandButtonHoverBorderColor = borderColorHover;
export const tableExpandButtonLineColor = Green;
