import {
    backgroundColorNormal,
    backgroundColorSuccess,
    backgroundColorWhite,
    borderColorDisabled,
    borderColorLight,
    ColorGreenDark,
    ColorGrey6,
    textColorOpacity,
} from './palette';

export const labelEditorButtonBg = backgroundColorNormal;
export const labelEditorButtonBgHover = ColorGreenDark;

export const labelEditorModuleBg = backgroundColorWhite;

export const labelEditorItemBg = ColorGrey6;
export const labelEditorItemBorder = borderColorDisabled;

export const labelEditorCreateBg = backgroundColorWhite;
export const labelEditorCreateBgActive = backgroundColorSuccess;
export const labelEditorCreateBorder = borderColorLight;
export const labelEditorCreateShadow = textColorOpacity;
