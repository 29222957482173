/**
 * Theme props for Widgets components
 */

// Search Accounts
export const widgetSearchAccountsListNumberWidth = '200px';
export const widgetSearchAccountsListBalanceWidth = '200px';
export const widgetSearchAccountsListSubscribersWidth = '200px';
export const widgetSearchAccountsListHeight = '400px';
export const widgetSearchAccountsRowHeight = '40px';
