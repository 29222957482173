/**
 * @author Evgenij.Tyan
 * @version 1.0.0
 */
import theme from 'styled-theming';
import colors from '../colors';

/**
 * Styled props for select
 */

export const fontColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.textColor},
  error: {standard: colors.textColor},
});

export const bgColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorWhite},
  error: {standard: colors.backgroundColorWhite},
});

/* Border Color */
export const borderColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.borderColorLight},
    filled: {standard: colors.borderColorNormal},
    error: {standard: colors.borderColorError},
});

export const borderColorFocus = theme.variants('mode', 'kind', {
    normal: {standard: colors.borderColorNormal},
    filled: {standard: colors.borderColorNormal},
    error: {standard: colors.borderColorNormal},
});

export const borderColorDisable = theme.variants('mode', 'kind', {
    normal: {standard: colors.borderColorDisabled},
    filled: {standard: colors.borderColorNormal},
    error: {standard: colors.borderColorDisabled},
});
