/**
 * @author Vladislav.Oshkanov
 * @author Aleksandr Perkhunov
 * @version 1.0.0
 *
 * Theme css for Button component
 */

import theme from 'styled-theming';
import colors from '../colors';
import * as fonts from '../fonts';

export const bgColor = theme.variants('mode', 'kind', {
    medium: { standard: colors.backgroundColorSuccess },
    mediumOutline: { standard: colors.backgroundColorWhite },
    mediumNormal: { standard: colors.backgroundColorSuccess },
    small: { standard: colors.backgroundColorWhite },
    table: { standard: colors.backgroundColorWhite },
    circle: { standard: colors.backgroundColorModal },
});

export const bgColorDisabled = theme.variants('mode', 'kind', {
    medium: { standard: colors.backgroundColorDisabled },
    mediumOutline: { standard: colors.backgroundColorWhite },
    mediumNormal: { standard: colors.backgroundColorDisabled },
    small: { standard: colors.backgroundColorWhite },
    table: { standard: colors.backgroundColorWhite },
    circle: { standard: colors.backgroundColorModal },
});

export const bgColorDown = theme.variants('mode', 'kind', {
    medium: { standard: colors.backgroundColorDown },
    mediumOutline: { standard: colors.backgroundColorDown },
    mediumNormal: { standard: colors.backgroundColorDown },
    small: { standard: colors.backgroundColorDown },
    table: { standard: colors.backgroundColorDisabledDark },
    circle: { standard: colors.backgroundColorDisabledDark },
});

export const bgColorDownDisabled = theme.variants('mode', 'kind', {
    medium: { standard: colors.backgroundColorDisabled },
    mediumOutline: { standard: colors.backgroundColorDisabled },
    mediumNormal: { standard: colors.backgroundColorDisabled },
    small: { standard: colors.backgroundColorDisabled },
    table: { standard: colors.backgroundColorWhite },
    circle: { standard: colors.backgroundColorModal },
});

export const bgColorHover = theme.variants('mode', 'kind', {
    medium: { standard: colors.backgroundColorHover },
    mediumOutline: { standard: colors.backgroundColorHover },
    mediumNormal: { standard: colors.backgroundColorHover },
    small: { standard: colors.backgroundColorHover },
    table: { standard: colors.backgroundColorHoverNormal },
    circle: { standard: colors.backgroundColorHoverNormal },
});

export const bgColorHoverDisabled = theme.variants('mode', 'kind', {
    medium: { standard: colors.backgroundColorDisabled },
    mediumOutline: { standard: colors.backgroundColorDisabled },
    mediumNormal: { standard: colors.backgroundColorDisabled },
    small: { standard: colors.backgroundColorDisabled },
    table: { standard: colors.backgroundColorWhite },
    circle: { standard: colors.backgroundColorModal },
});

export const height = theme.variants('mode', 'kind', {
    medium: { standard: '48px' },
    mediumOutline: { standard: '48px' },
    mediumNormal: { standard: '32px' },
    small: { standard: '32px' },
    table: { standard: '32px' },
    circle: { standard: '32px' },
});

export const padding = theme.variants('mode', 'kind', {
    medium: { standard: '32px' },
    mediumOutline: { standard: '32px' },
    mediumNormal: { standard: '32px' },
    small: { standard: '15px' },
    table: { standard: '12px' },
    none: { standard: '0' },
    circle: { standard: '6px' },
});

export const border = theme.variants('mode', 'kind', {
    medium: { standard: '1px solid transparent' },
    mediumOutline: { standard: `1px solid ${colors.borderColorSuccess}` },
    mediumNormal: { standard: '1px solid transparent' },
    small: { standard: `1px solid ${colors.borderColorSuccess}` },
    table: { standard: 'none' },
    circle: { standard: 'none' },
});

export const borderDisabled = theme.variants('mode', 'kind', {
    medium: { standard: `1px solid ${colors.borderColorLight}` },
    mediumOutline: { standard: `1px solid ${colors.borderColorLight}` },
    mediumNormal: { standard: `1px solid ${colors.borderColorLight}` },
    small: { standard: `1px solid ${colors.borderColorLight}` },
    table: { standard: 'none' },
    circle: { standard: 'none' },
});

export const borderDown = theme.variants('mode', 'kind', {
    medium: { standard: '1px solid transparent' },
    mediumOutline: { standard: `1px solid ${colors.borderColorDown}` },
    mediumNormal: { standard: '1px solid transparent' },
    small: { standard: `1px solid ${colors.borderColorDown}` },
    table: { standard: 'none' },
    circle: { standard: 'none' },
});

export const borderDownDisabled = theme.variants('mode', 'kind', {
    medium: { standard: '1px solid transparent' },
    mediumOutline: { standard: `1px solid ${colors.borderColorDisabled}` },
    mediumNormal: { standard: '1px solid transparent' },
    small: { standard: `1px solid ${colors.borderColorDisabled}` },
    table: { standard: 'none' },
    circle: { standard: 'none' },
});

export const borderHover = theme.variants('mode', 'kind', {
    medium: { standard: '1px solid transparent' },
    mediumOutline: { standard: `1px solid ${colors.borderColorHover}` },
    mediumNormal: { standard: '1px solid transparent' },
    small: { standard: `1px solid ${colors.borderColorHover}` },
    table: { standard: 'none' },
    circle: { standard: 'none' },
});

export const borderHoverDisabled = theme.variants('mode', 'kind', {
    medium: { standard: '1px solid transparent' },
    mediumOutline: { standard: `1px solid ${colors.borderColorDisabled}` },
    mediumNormal: { standard: '1px solid transparent' },
    small: { standard: `1px solid ${colors.borderColorDisabled}` },
    table: { standard: 'none' },
    circle: { standard: 'none' },
});

export const color = theme.variants('mode', 'kind', {
    medium: { standard: colors.textColorWhite },
    mediumOutline: { standard: colors.textColorSuccess },
    mediumNormal: { standard: colors.textColorWhite },
    small: { standard: colors.textColorSuccess },
    table: { standard: colors.textColor },
    circle: { standard: colors.textColor },
});

export const colorDisabled = theme.variants('mode', 'kind', {
    medium: { standard: colors.textDisabledColor },
    mediumOutline: { standard: colors.textDisabledColor },
    mediumNormal: { standard: colors.textDisabledColor },
    small: { standard: colors.textDisabledColor },
    table: { standard: colors.textColor },
    circle: { standard: colors.textColor },
});

export const colorHover = theme.variants('mode', 'kind', {
    medium: { standard: colors.textColorWhite },
    mediumOutline: { standard: colors.textColorWhite },
    mediumNormal: { standard: colors.textColorWhite },
    small: { standard: colors.textColorWhite },
    table: { standard: colors.textColor },
    circle: { standard: colors.textColor },
});

export const colorHoverDisabled = theme.variants('mode', 'kind', {
    medium: { standard: colors.textDisabledColor },
    mediumOutline: { standard: colors.textDisabledColor },
    mediumNormal: { standard: colors.textDisabledColor },
    small: { standard: colors.textDisabledColor },
    table: { standard: colors.textColor },
    circle: { standard: colors.textColor },
});

export const colorDown = theme.variants('mode', 'kind', {
    mediumNormal: { standard: colors.textColorWhite },
    smallNormal: { standard: colors.textColorWhite },
    table: { standard: colors.textColor },
    circle: { standard: colors.textColor },
});

export const colorDownDisabled = theme.variants('mode', 'kind', {
    medium: { standard: colors.textDisabledColor },
    mediumOutline: { standard: colors.textDisabledColor },
    mediumNormal: { standard: colors.textDisabledColor },
    small: { standard: colors.textDisabledColor },
    table: { standard: colors.textColor },
    circle: { standard: colors.textColor },
});

export const font = theme.variants('mode', 'kind', {
    medium: { standard: fonts.textMainBold },
    mediumOutline: { standard: fonts.textMainBold },
    mediumNormal: { standard: fonts.textMainBold },
    small: { standard: fonts.textMainBold },
    table: { standard: fonts.textSmall },
    circle: { standard: fonts.textSmall },
});
