/**
 * @author Vladislav.Oshkanov
 * @version 1.0.0
 *
 * TabMenu css style properties
 */

import theme from 'styled-theming';
import colors from '../colors';

export const color = theme.variants('mode', 'kind', {
    normal: {standard: colors.textColor},
});

export const colorSelected = theme.variants('mode', 'kind', {
    normal: {standard: colors.textColorActive},
});

export const colorSubMenu = theme.variants('mode', 'kind', {
    normal: {standard: colors.textLinkColor},
});
