/**
 * Colors for wizard
 */
import { ClearWhite, FreshAsphalt, FreshAsphalt50, Fury, Green, SpbSky0, SpbSky1 } from './palette';

// Header
export const wizardHeaderBorderBottomColor = SpbSky1;

// Circles
export const wizardHeaderStepCircleTextColor = FreshAsphalt50;
export const wizardHeaderStepCircleBgColor = SpbSky0;
export const wizardHeaderStepCircleBorderColor = SpbSky0;

export const wizardHeaderStepCircleActiveTextColor = FreshAsphalt;
export const wizardHeaderStepCircleActiveBgColor = ClearWhite;
export const wizardHeaderStepCircleActiveBorderColor = Green;

// Hint
export const wizardHintValidationColorDefault = FreshAsphalt50;
export const wizardHintValidationColorError = Fury;

// Display
export const wizardSummaryBgColor = SpbSky0;
export const wizardSummaryParamTitleColor = FreshAsphalt50;
