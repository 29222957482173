import { FreshAsphalt } from './palette';

// Circle
export const loaderCircleColor = FreshAsphalt;

// Linear
export const loaderLinearBgColor = `linear-gradient(
    to right,
    rgba(216, 216, 216, 0.5) 8%,
    rgba(238, 238, 238, 0.5) 33%,
    rgba(216, 216, 216, 0.5) 60%)`;
