/**
 *
 *
 * @author Evgenij Tyan
 * @version 1.0.0
 * @since 1.0.0
 */
import {
    backgroundColorWhite,
    borderColorNormal,
    borderColorSeparate,
    ClearWhite,
    Green,
    textColorOpacity,
} from './palette';

export const corporateHeaderSeparateBorderColor = borderColorSeparate;
export const corporateHeaderBgColor = backgroundColorWhite;
export const corporateHeaderBorder = borderColorSeparate;
export const corporateHeaderBorderUnderline = borderColorNormal;
export const corporateHeaderTextSecondary = textColorOpacity;

export const corporateHeaderCounterFont = ClearWhite;
export const corporateHeaderCounterBg = Green;
