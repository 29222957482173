/**
 * @author Yakov.Gushchin
 * @version 1.0.0
 */
import theme from 'styled-theming';
import colors from '../colors';
import { FreshAsphalt50, Fury } from '../colors/palette';

/* Border Color */
export const borderColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.borderColorLight},
    verify: {standard: colors.borderColorSuccess},
    error: {standard: colors.borderColorError},
    filled: {standard: colors.borderColorNormal},
});

export const borderColorFocus = theme.variants('mode', 'kind', {
    normal: {standard: colors.borderColorNormal},
    verify: {standard: colors.borderColorNormal},
    error: {standard: colors.borderColorError},
    filled: {standard: colors.borderColorNormal},
});

export const borderColorDisable = theme.variants('mode', 'kind', {
    normal: {standard: colors.borderColorDisabled},
    verify: {standard: colors.borderColorDisabled},
    error: {standard: colors.borderColorDisabled},
    filled: {standard: colors.borderColorNormal},
});

/* Background */
export const backgroundColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.backgroundColorWhite},
    verify: {standard: colors.backgroundColorWhite},
    error: {standard: colors.backgroundColorWhite},
});

export const backgroundColorFocus = theme.variants('mode', 'kind', {
    normal: {standard: colors.backgroundColorWhite},
    verify: {standard: colors.backgroundColorWhite},
    error: {standard: colors.backgroundColorWhite},
});

export const backgroundColorDisable = theme.variants('mode', 'kind', {
    normal: {standard: colors.backgroundColorDisabled},
    verify: {standard: colors.backgroundColorDisabled},
    error: {standard: colors.backgroundColorDisabled},
});

/* Input Font color */
export const fontColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.textColor},
    verify: {standard: colors.textColor},
    error: {standard: colors.textColor},
});

export const fontColorFocus = theme.variants('mode', 'kind', {
    normal: {standard: colors.textFocusColor},
    verify: {standard: colors.textFocusColor},
    error: {standard: colors.textFocusColor},
});

export const fontColorDisable = theme.variants('mode', 'kind', {
    normal: {standard: colors.textDisabledColor},
    verify: {standard: colors.textDisabledColor},
    error: {standard: colors.textDisabledColor},
});

export const placeholderFontColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.textColorOpacity},
    verify: {standard: colors.textColorOpacity},
    error: {standard: colors.textColorOpacity},
});

export const hintFontColor = theme.variants('mode', 'kind', {
    normal: { standard: FreshAsphalt50 },
    verify: { standard: FreshAsphalt50 },
    error: { standard: Fury },
});

/* Label font color */
export const labelColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.textLabelColor},
    verify: {standard: colors.textLabelColor},
    error: {standard: colors.textLabelColor},
});
