/**
 * @author Evgenij.Tyan
 * @version 1.0.0
 */
import theme from 'styled-theming';
import colors from '../colors';

/**
 * Styled props for select item
 */
export const fontColor = theme.variants('mode', 'kind', {
  normal: {standard: colors.textColor},
});

export const bgColorHover = theme.variants('mode', 'kind', {
  normal: {standard: colors.backgroundColorHoverNormal},
});
