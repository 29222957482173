/**
 * @author Vladislav.Oshkanov
 * @version 1.0.0
 *
 * Popup css style properties
 */

import theme from "styled-theming";
import colors from '../colors';

export const bgColor = theme.variants('mode', 'kind', {
    normal: {standard: colors.backgroundColorNormal},
});
