/**
 * @author Aleksandr Perkhunov
 * @version 1.0.0
 *
 * FOOTER
 */
import { backgroundColorModal, borderColorLight, textColor, textColorOpacity } from './palette';

export const footerBorderColor = borderColorLight;
export const footerBg = backgroundColorModal;
export const footerMenuButtonColor = textColorOpacity;
export const footerMenuButtonColorHover = textColor;
export const footerCopyrightColor = textColorOpacity;
