/**
 * Colors for AccountSelect component
 */

import { ClearWhite, FreshAsphalt, FreshAsphalt50, Fury, SpbSky1, SpbSky150, SpbSky2 } from './palette';

// Display
export const accountSelectDisplayBgColorDefault = ClearWhite;
export const accountSelectDisplayBgColorDisabled = SpbSky1;
export const accountSelectDisplayTextColorDefault = FreshAsphalt;
export const accountSelectDisplayTextColorSecondary = FreshAsphalt50;
export const accountSelectDisplayBorderColorDefault = SpbSky2;
export const accountSelectDisplayBorderColorDisabled = SpbSky1;
export const accountSelectDisplayBorderColorError = Fury;

// Label
export const accountSelectLabelTextColor = FreshAsphalt;

// List
export const accountSelectListBodyBgColor = ClearWhite;
export const accountSelectListHeaderTextColor = FreshAsphalt50;
export const accountSelectListHoverBgColor = SpbSky150;

// Loader
export const accountSelectLoaderColor = ClearWhite;
export const accountSelectLoaderCircleColor = FreshAsphalt;

// NotFound
export const accountSelectNotFoundTextColor = FreshAsphalt50;
