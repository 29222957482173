import * as accountSelect from './accountSelect';
import * as banner from './banner';
import * as basket from './basket';
import * as charts from './charts';
import * as corporateHeader from './corporateHeader';
import * as calendarCell from './calendarCell';
import * as createInquiry from './createInquiry';
import * as dragAndDrop from './dragAndDrop';
import * as dropDownFilter from './dropDownFilter';
import * as footer from './footer';
import * as header from './header';
import * as hintPopup from './hintPopup';
import * as infoBlock from './infoBlock';
import * as labelEditor from './labelEditor';
import * as labelsImport from './labelsImport';
import * as link from './link';
import * as loader from './loader';
import * as modal from './modal';
import * as pageAuth from './pageAuth';
import * as pageDashboard from './pageDashboard';
import * as pageExpensesAnalyzer from './pageExpensesAnalyzer';
import palette from './palette';
import * as rangePicker from './rangePicker';
import * as skeleton from './skeleton';
import * as superSearch from './superSearch';
import * as table from './table';
import * as tableFooter from './tableFooter';
import * as tableSetting from './tableSetting';
import * as tariffsAndServices from './tariffsAndServices';
import * as tooltip from './tooltip';
import * as wizard from './wizard';

export default {
    ...accountSelect,
    ...banner,
    ...basket,
    ...calendarCell,
    ...charts,
    ...corporateHeader,
    ...createInquiry,
    ...dragAndDrop,
    ...dropDownFilter,
    ...footer,
    ...header,
    ...hintPopup,
    ...infoBlock,
    ...labelsImport,
    ...labelEditor,
    ...link,
    ...loader,
    ...modal,
    ...pageAuth,
    ...pageDashboard,
    ...pageExpensesAnalyzer,
    ...palette,
    ...rangePicker,
    ...skeleton,
    ...superSearch,
    ...table,
    ...tableFooter,
    ...tableSetting,
    ...tariffsAndServices,
    ...tooltip,
    ...wizard,
};
